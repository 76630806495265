import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Content from "../components/Content";
import ContentJustified from "../components/ContentJustified";
import Container from "@material-ui/core/Container";
import HeaderSection from "../components/HeaderSection";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  image,
  share,
  location,
}) => {
  const theme = useTheme();
  const PostContent = contentComponent || Content;

  const linkedinShare = () => {
    const newWindow = window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const facebookShare = () => {
    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${location.href}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" style={{ marginTop: theme.spacing(10) }}>

        <HeaderSection
          header={title}
          subheader={description}
          location={location}
        />
        {/* <Container maxWidth="md" style={{ marginTop: theme.spacing(10) }}> */}
        <PostContent content={content} />
        {/* </Container> */}
        {share && <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="textPrimary" component="h5">
            Share this article
          </Typography>
          <Button onClick={linkedinShare} size="large" color="primary">
            <LinkedInIcon fontSize="large" />
          </Button>
          <Button onClick={facebookShare} size="large" color="primary">
            <FacebookIcon fontSize="large" />
          </Button>
        </Grid>}
      </Container>
    </React.Fragment>
  );
};

BlogPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.object,
  share: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  post: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout location={location}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt || "nothin’"}
        // image={post.frontmatter.image.childImageSharp.fluid.src}
        pathname={post.fields.slug}
        article
      />
      <BlogPostTemplate
        location={location}
        content={post.html}
        share={post.frontmatter.share}
        contentComponent={ContentJustified}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        archived
        share
      }
    }
  }
`;
